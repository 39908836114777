<template>
  <b-card
    title="Coefficienti"
  >
    <b-card-body>
      <b-row>
        <b-col md="12">
          <b-table-simple
            hover
            caption-top
            responsive
            class="rounded-bottom mb-0"
          >
            <b-thead head-variant="custom">
              <b-tr class="text-center">
                <b-th
                  colspan="8"
                  variant="danger"
                >
                  {{ namePack }}
                </b-th>
              </b-tr>
              <b-tr>
                <b-th colspan="1">
                  <span class="sr-only">ZONA</span>
                </b-th>
                <b-th
                  class="text-center"
                  colspan="5"
                >
                  DURATA IN MESI
                </b-th>
                <b-th colspan="2">
                  <span class="sr-only">Provvigioni & Tasse</span>
                </b-th>
              </b-tr>
              <b-tr>
                <b-th
                  variant="danger"
                  class="text-center"
                >
                  ZONA:
                </b-th>
                <b-th
                  variant="info"
                  class="text-center"
                >
                  12
                </b-th>
                <b-th
                  variant="info"
                  class="text-center"
                >
                  24
                </b-th>
                <b-th
                  variant="info"
                  class="text-center"
                >
                  36
                </b-th>
                <b-th
                  variant="info"
                  class="text-center"
                >
                  48
                </b-th>
                <b-th
                  variant="info"
                  class="text-center"
                >
                  60
                </b-th>
                <b-th
                  variant="success"
                  class="text-center"
                >
                  Provvigioni
                </b-th>
                <b-th
                  variant="warning"
                  class="text-center"
                >
                  Tasse
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(item, index) in FactoreToEditObj"
                :key="index"
              >
                <b-td class="text-center">
                  <b-link
                    :to="{ name: 'edit-zona', params: { id: item.Id }}"
                  >
                    {{ item.name }}
                  </b-link>
                </b-td>
                <b-td
                  v-if="selectedCell === `${index}_12`"
                  class="text-center"
                  :variant="item.v[0].factor>0?'success':'danger'"
                >
                  <b-form-input
                    :ref="`${index}_12`"
                    v-model="item.v[0].factor"
                    type="number"
                    @keyup.enter="editCellHandler(`${index}_24`)"
                  />
                </b-td>
                <b-td
                  v-else
                  class="text-center"
                  :variant="item.v[0].factor>0?'success':'danger'"
                  @click="editCellHandler(`${index}_12`)"
                >
                  <span>{{ item.v[0].factor }} {{ (coefficientiType=='M')?'%':'€' }}</span>
                </b-td>
                <b-td
                  v-if="selectedCell === `${index}_24`"
                  class="text-center"
                  :variant="item.v[1].factor>0?'success':'danger'"
                >
                  <b-form-input
                    :ref="`${index}_24`"
                    v-model="item.v[1].factor"
                    type="number"
                    @keyup.enter="editCellHandler(`${index}_36`)"
                  />
                </b-td>
                <b-td
                  v-else
                  class="text-center"
                  :variant="item.v[1].factor>0?'success':'danger'"
                  @click="editCellHandler(`${index}_24`)"
                >
                  <span>{{ item.v[1].factor }} {{ (coefficientiType=='M')?'%':'€' }}</span>
                </b-td>
                <b-td
                  v-if="selectedCell === `${index}_36`"
                  class="text-center"
                  :variant="item.v[2].factor>0?'success':'danger'"
                >
                  <b-form-input
                    :ref="`${index}_48`"
                    v-model="item.v[2].factor"
                    type="number"
                    @keyup.enter="editCellHandler(`${index}_48`)"
                  />
                </b-td>
                <b-td
                  v-else
                  class="text-center"
                  :variant="item.v[2].factor>0?'success':'danger'"
                  @click="editCellHandler(`${index}_36`)"
                >
                  <span>{{ item.v[2].factor }} {{ (coefficientiType=='M')?'%':'€' }}</span>
                </b-td>
                <b-td
                  v-if="selectedCell === `${index}_48`"
                  class="text-center"
                  :variant="item.v[3].factor>0?'success':'danger'"
                >
                  <b-form-input
                    :ref="`${index}_48`"
                    v-model="item.v[3].factor"
                    type="number"
                    @keyup.enter="editCellHandler(`${index}_60`)"
                  />
                </b-td>
                <b-td
                  v-else
                  class="text-center"
                  :variant="item.v[3].factor>0?'success':'danger'"
                  @click="editCellHandler(`${index}_48`)"
                >
                  <span>{{ item.v[3].factor }} {{ (coefficientiType=='M')?'%':'€' }}</span>
                </b-td>
                <b-td
                  v-if="selectedCell === `${index}_60`"
                  class="text-center"
                  :variant="item.v[4].factor>0?'success':'danger'"
                >
                  <b-form-input
                    :ref="`${index}_60`"
                    v-model="item.v[4].factor"
                    type="number"
                    @keyup.enter="editCellHandler(`${index}_p`)"
                  />
                </b-td>
                <b-td
                  v-else
                  class="text-center"
                  :variant="item.v[4].factor>0?'success':'danger'"
                  @click="editCellHandler(`${index}_60`)"
                >
                  <span>{{ item.v[4].factor }} {{ (coefficientiType=='M')?'%':'€' }}</span>
                </b-td>
                <b-td
                  v-if="selectedCell === `${index}_p`"
                  class="text-center"
                  :variant="item.v[0].provvigione>0?'success':'danger'"
                >
                  <b-input-group
                    :append="(commissionType=='P')?'%':'€'"
                    class="input-group-merge"
                  >
                    <b-form-input
                      :ref="`${index}_p`"
                      v-model="item.v[0].provvigione"
                      type="number"
                      @keyup.enter="editCellHandler(`${index}_t`)"
                    />
                  </b-input-group>
                </b-td>
                <b-td
                  v-else
                  class="text-center"
                  :variant="item.v[0].provvigione>0?'success':'danger'"
                  @click="editCellHandler(`${index}_p`)"
                >
                  <span>{{ item.v[0].provvigione }} {{ (commissionType=='P')?'%':'€' }} </span>
                </b-td>
                <b-td
                  v-if="selectedCell === `${index}_t`"
                  class="text-center"
                  :variant="item.v[0].tax>0?'success':'danger'"
                >
                  <b-input-group
                    :append="(commissionType=='P')?'%':'€'"
                    class="input-group-merge"
                  >
                    <b-form-input
                      :ref="`${index}_t`"
                      v-model="item.v[0].tax"
                      type="number"
                      @keyup.enter="editCellHandler(`${index+1}_12`)"
                    />
                  </b-input-group>
                </b-td>
                <b-td
                  v-else
                  class="text-center"
                  :variant="item.v[0].tax>0?'success':'danger'"
                  @click="editCellHandler(`${index}_t`)"
                >
                  <span>{{ item.v[0].tax }}%</span>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-footer>
      <b-row>
        <b-col md="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="saveFactor"
          >
            Salva Coefficienti
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="resetObject"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BCardBody, BCardFooter, BFormInput, BInputGroup, BLink, BRow, BCol, BButton,
} from 'bootstrap-vue'
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import productStoreModule from '../Product/productStoreModule'

export default {
  components: {
    BCard,
    BInputGroup,
    BFormInput,
    BTableSimple,
    BThead,
    BTr,
    BLink,
    BTh,
    BTd,
    BTbody,
    BRow,
    BCol,
    BButton,
    BCardBody,
    BCardFooter,
  },
  directives: {
    Ripple,
  },
  props: {
    namePack: {
      type: String,
      default: null,
      required: true,
    },
    indexPack: {
      type: String,
      default: null,
      required: true,
    },
    factorToEdit: {
      type: Array,
      required: true,
    },
    originalFactor: {
      type: Array,
      required: true,
    },
    agencyId: {
      type: Number,
      default: null,
      required: false,
    },
    coefficientiType: {
      type: String,
      default: "M", // M: millesimi - V: valore
      required: false,
    },
    commissionType: {
      type: String,
      default: 'P', // P=percentuale; V=valore
      required: false,
    },
  },
  setup(props) {
    const PRODUCT_DEALER_STORE_MODULE_NAME = 'app-product'

    const FactoreToEditObj = ref({})
    const OriginalFactorObj = ref({})

    // Register module
    if (!store.hasModule(PRODUCT_DEALER_STORE_MODULE_NAME)) store.registerModule(PRODUCT_DEALER_STORE_MODULE_NAME, productStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_DEALER_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_DEALER_STORE_MODULE_NAME)
    })

    const user = getUserData()
    const resetObject = () => {
      FactoreToEditObj.value = JSON.parse(JSON.stringify(OriginalFactorObj.value))
    }

    watch(props, () => {
      if (props.agencyId) {
        // fetchFiles()
      } else {
        FactoreToEditObj.value = JSON.parse(JSON.stringify(props.originalFactor))
        OriginalFactorObj.value = JSON.parse(JSON.stringify(props.originalFactor))
      }
    })


    return {
      user, resetObject, FactoreToEditObj, OriginalFactorObj,
    }
  },
  data() {
    return {
      selectedCell: null,
    }
  },
  methods: {
    editCellHandler(name) {
      this.selectedCell = name
      // document.getElementById(name).focus()
      // this.$refs[`input_${name}`][0].focus()
      // this.$refs[name][0].$el.focus()
    },
    saveFactor() {
      productStoreModule.actions.saveFactor(null, { PackageId: this.indexPack, FactorList: this.FactoreToEditObj, userData: this.user }).then(response => {
        this.$swal({
          icon: 'success',
          title: 'Modifica effettuata!',
          text: 'I coefficienti sono stati memorizzati.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        }).then(resp => {
          this.$router.go({ name: 'edit-program', params: { id: this.indexPack } })
        })
      }).catch(error => {
        console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>

