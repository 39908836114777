<template>
  <section id="upsert-bundle">
    <!-- Table Container Card -->
    <b-card
      title="Pacchetto Assicurativo"
    >
      <b-overlay
        :show="showOverlay"
        rounded="sm"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="BundleRules"
        >
          <b-form @submit.prevent="handleSubmit(saveBundle())">
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Nome"
                  label-for="Name"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Nome"
                  >
                    <b-form-input
                      id="Name"
                      v-model="ObjectToEdit.Name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Nome Pacchetto Assicurativo"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                  label="Decrizione preventivo"
                  label-for="QuoteDescription"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules=""
                    name="Descrizione preventivo"
                  >
                    <b-form-input
                      id="QuoteDescription"
                      v-model="ObjectToEdit.QuoteDescription"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                  label="Prodotto Base"
                  label-for="product"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Prodotto Base"
                  >
                    <v-select
                      v-model="ObjectToEdit.product"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="true"
                      :options="productList"
                      label="Name"
                      index="Id"
                      required
                      :reduce="val => val.Id"
                      class="fixborder"
                      input-id="product"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-form-group
                  label="Abilitato"
                  label-for="is-enabled-bundle"
                >
                  <b-form-checkbox
                    v-model="ObjectToEdit.IsEnabled"
                    input-id="is-enabled-bundle"
                    switch
                    value="Y"
                    unchecked-value="N"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-form-group
                  label="Coefficienti"
                  label-for="type-bundle"
                >
                  <b-form-checkbox
                    v-model="ObjectToEdit.PriceType"
                    input-id="type-bundle"
                    switch
                    value="V"
                    unchecked-value="M"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="DollarSignIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="PercentIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-form-group
                  label="Provvigioni"
                  label-for="type-bundle"
                >
                  <b-form-checkbox
                    v-model="ObjectToEdit.CommissionType"
                    input-id="type-bundle"
                    switch
                    value="V"
                    unchecked-value="P"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="DollarSignIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="PercentIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <!-- submit and reset -->
              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  {{ (ObjectToEdit.Id)? "Salva Testata" : "Aggiungi" }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="resetObject"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-card>

    <coefficienti
      v-if="route.params.id != 'new'"
      :name-pack="ObjectToEdit.Name"
      :index-pack="ObjectToEdit.Id"
      :factor-to-edit="FactorToEdit"
      :original-factor="OriginalFactor"
      :coefficienti-type="ObjectToEdit.PriceType"
      :commission-type="ObjectToEdit.CommissionType"
    />
  </section>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BCard,
  BOverlay,
  BFormTextarea,
  BFormCheckbox,
  BFormInput,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import productStoreModule from '../Product/productStoreModule'
import Coefficienti from './Coefficienti.vue'
// import companyStoreModule from '../Company/companyStoreModule'

export default {
  components: {
    BCard,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormCheckbox,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Coefficienti,
  },
  directives: {
    Ripple,
  },
  // mounted() {
  //   companyStoreModule.actions.fetchCompanies().then(res => {
  //     this.companyList = res.data.companies
  //   }).catch(err => { console.log(" Companies error"); console.log(err) })
  // },
  methods: {
    saveBundle() {
      this.$refs.BundleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          store.dispatch('app-product/AddUpdatePackage', { pack: this.ObjectToEdit, userData: this.userData })
            .then(response => {
              const id = response.data.Id
              this.$swal({
                icon: 'success',
                title: 'Modifica effettuata!',
                text: 'Il pacchetto è stato memorizzato.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(resp => {
                this.$router.push({ name: 'bundlelist' })
                // this.$router.go({ name: 'add-edit-bundle', params: { id } })
              })
            }).catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
  setup() {
    const PRODUCT_DEALER_STORE_MODULE_NAME = 'app-product'
    const showOverlay = ref(true)
    const productList = ref([])
    const emptyObject = {
      Id: null,
      Name: "",
      IsEnabled: "N",
      PriceType: "M",
      product: null,
    }
    const ObjectToEdit = ref({})
    const OriginalObject = ref({})
    const FactorToEdit = ref({})
    const OriginalFactor = ref({})

    // Register module
    if (!store.hasModule(PRODUCT_DEALER_STORE_MODULE_NAME)) store.registerModule(PRODUCT_DEALER_STORE_MODULE_NAME, productStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_DEALER_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_DEALER_STORE_MODULE_NAME)
    })
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)

    const fetchProducts = () => {
      showOverlay.value = true
      productStoreModule.actions.fetchProductSelect().then(res => {
        productList.value = res.data.products
        showOverlay.value = false
      }).catch(err => {
        console.log(" Products error")
        console.log(err)
        showOverlay.value = false
      })
    }

    const fecthPackageFactor = id => {
      productStoreModule.actions.fecthPackageFactor(null, { id })
        .then(resp => {
          console.log()
          if (resp.data.ResultCode == 0) {
            FactorToEdit.value = resp.data.Data
            OriginalFactor.value = JSON.parse(JSON.stringify(resp.data.Data))
          }
        }).catch(err => {
          console.log("err", err)
        })
    }

    const fetchBundle = Id => {
      showOverlay.value = true
      store.dispatch(`${PRODUCT_DEALER_STORE_MODULE_NAME}/fetchPackage`, {
        id: Id,
      })
        .then(response => {
          ObjectToEdit.value = response.data.bundle
          console.log(response.data)
          OriginalObject.value = JSON.parse(JSON.stringify(response.data.bundle))
          fecthPackageFactor(ObjectToEdit.value.Id)
          showOverlay.value = false
        }).catch(error => {
          ObjectToEdit.value = JSON.parse(JSON.stringify(emptyObject))
          OriginalObject.value = JSON.parse(JSON.stringify(emptyObject))
          showOverlay.value = false
        })
    }

    const resetObject = () => {
      ObjectToEdit.value = JSON.parse(JSON.stringify(OriginalObject.value))
    }

    watch(routeParams, () => {
      try {
        if (routeParams.value.id == 'new') {
          ObjectToEdit.value = JSON.parse(JSON.stringify(emptyObject))
        } else {
          fetchBundle(routeParams.value.id)
        }
      } catch (error) {
        ObjectToEdit.value = null
      }
    })

    try {
      if (routeParams.value.id == 'new') {
        ObjectToEdit.value = emptyObject
      } else {
        fetchBundle(routeParams.value.id)
      }
    } catch (error) {
      ObjectToEdit.value = null
    }

    const userData = getUserData()
    fetchProducts()


    return {
      ObjectToEdit,
      OriginalObject,
      OriginalFactor,
      FactorToEdit,
      resetObject,
      showOverlay,
      productList,
      fetchBundle,
      userData,
      route,
      router,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .agency-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

